var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lock-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "lock-form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "auto-complete": "off",
            "label-position": "left"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleLogin($event)
            }
          }
        },
        [
          _c(
            "el-form-item",
            { staticClass: "text-center" },
            [
              _c("el-avatar", {
                attrs: { size: 80, src: _vm.user.avatar || _vm.defaultAvatar }
              }),
              _c("p", { staticClass: "phone" }, [
                _vm._v(_vm._s(_vm.user.phone))
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c("el-input", {
                ref: "password",
                attrs: {
                  "prefix-icon": "iconfont icon-mima",
                  placeholder: "请输入密码继续登录",
                  "show-password": "",
                  "auto-complete": "off"
                },
                model: {
                  value: _vm.form.password,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "password",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.password"
                }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "wb100 margin-bottom",
              attrs: {
                loading: _vm.loading,
                type: "primary",
                "native-type": "submit"
              }
            },
            [_vm._v("登录")]
          ),
          _c(
            "el-form-item",
            { staticClass: "text-center" },
            [
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.logout } },
                [_vm._v("使用其他账号登录")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("copyright")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }